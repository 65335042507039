import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {BsReddit} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://linkedin.com" target='_blank'> <BsLinkedin /></a>
        <a href="https://githun.com" target='_blank'> <BsGithub /></a>
        <a href="https://rediet.com" target='_blank'> <BsReddit /></a>
    </div>
  )
}

export default HeaderSocials