import React from "react";
import "./services.css";
import { AiFillCheckCircle } from "react-icons/ai";
const Services = () => {
  return (
    <section id="services">
      <h5>Offers</h5>
      <h2>Services</h2>
      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Mobile App Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <AiFillCheckCircle className="service__list-icon" />
              <p>
                {" "}
                The process of creating software for mobile devices, such as
                smartphones, tablets, and digital assistants.
              </p>
            </li>

            <li>
              <AiFillCheckCircle className="service__list-icon" />
              <p>
                Writing code to create the software using React, React daynamic{" "}
              </p>
            </li>

            <li>
              <AiFillCheckCircle className="service__list-icon" />
              <p>
                Designing the app fit with the organizational mission and vision
              </p>
            </li>

            <li>
              <AiFillCheckCircle className="service__list-icon" />
              <p>Ensuring the mobile app and mobile platform are both secure</p>
            </li>
            <li>
              <AiFillCheckCircle className="service__list-icon" />
              <p>Using proper encryption of sensitive personal data</p>
            </li>
            <li>
              <AiFillCheckCircle className="service__list-icon" />
              <p>Using authorized APIs to easly access on Websites </p>
            </li>
          </ul>
        </article>
        {/* End of UI */}
        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <AiFillCheckCircle className="service__list-icon" />
              <p>
                Create a website that is easy for users to navigate and find
                links.{" "}
              </p>
            </li>

            <li>
              <AiFillCheckCircle className="service__list-icon" />
              <p>
                Developing a simple single static page to complex web
                applications, electronic businesses, and social network
                services.
              </p>
            </li>

            <li>
              <AiFillCheckCircle className="service__list-icon" />
              <p>
                Creating, building, and maintaining websites and web
                applications that run online on a browser.{" "}
              </p>
            </li>

            <li>
              <AiFillCheckCircle className="service__list-icon" />
              <p>
                HTML, CSS, JavaScript use those “big three” of web development,
                and server-side languages like Java, C++, Python, and SQL
              </p>
            </li>
          </ul>
        </article>
        {/* End of web development */}
        <article className="service">
          <div className="service__head">
            <h3>Website Management System</h3>
          </div>
          <ul className="service__list">
            <li>
              <AiFillCheckCircle className="service__list-icon" />
              <p>
              Set of tools and services that allows teams to build, launch, and manage websites. 

              </p>
            </li>

            <li>
              <AiFillCheckCircle className="service__list-icon" />
              <p>
              Users to build and manage a website without having to code it from scratch, or know how to code at all
              </p>
            </li>

            <li>
              <AiFillCheckCircle className="service__list-icon" />
              <p>
              That provides an organization with a way to manage digital information on a website.
              </p>
            </li>

            <li>
              <AiFillCheckCircle className="service__list-icon" />
              <p>
              an integrated set of tools and services allowing teams to build, launch, and manage websites
              </p>
            </li>

            <li>
              <AiFillCheckCircle className="service__list-icon" />
              <p>
              Document management, Records management, Digital asset management (audio, video, graphic content), Web content management.
              </p>
            </li>
          </ul>
        </article>
        {/* End of content creation */}
      </div>
    </section>
  );
};

export default Services;
