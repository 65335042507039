import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/portfolio11.png";
import IMG2 from "../../assets/portfolio12.jpg";
import IMG3 from "../../assets/portfolio13.png";
import IMG4 from "../../assets/portfolio14.jpg";
import IMG5 from "../../assets/portfolio15.png";
import IMG6 from "../../assets/portfolio16.png";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "home page web design for blogs",
    github: "https://github.com",
    demo: "https://dribbble.com/shots/20394039-Apres-Home-page-web-design",
  },
  {
    id: 2,
    image: IMG2,
    title: "Electronics Motion Technology",
    github: "https://github.com",
    demo: "https://dribbble.com/shots/21590155-Ampdrive-Website",
  },
  {
    id: 3,
    image: IMG3,
    title: "Descover and Traveling",
    github: "https://github.com",
    demo: "https://dribbble.com/shots/20477712-Co-hiking-Website-Design-business-landing-web-page-site-design",
  },
  {
    id: 4,
    image: IMG4,
    title: "Real State Agents and Contaraction Company",
    github: "https://github.com",
    demo: "https://dribbble.com/shots/20349834-Web-Design",
  },
  {
    id: 5,
    image: IMG5,
    title: "Hotel Booking and Traveling Agency ",
    github: "https://github.com",
    demo: "https://dribbble.com/shots/portfolio19996339-Travel-Web-Header-Design",
  },
  {
    id: 6,
    image: IMG6,
    title: "Restorant management system and delivery",
    github: "https://github.com",
    demo: "https://dribbble.com/shots/20078163-Web-Design",
  },
];
const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Real Projects</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <div className="portfolio__item-cta">
                <h3>{title}</h3>
                <a href={github} className="btn" target="_blank">
                  Github
                </a>
                <a href={demo} className="btn btn-primary" target="_blank">
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
