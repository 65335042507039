import React from "react";
import "./experience.css";
import { BsBookmarkCheckFill, BsBootstrapFill, BsFiletypePhp } from "react-icons/bs";
import {DiHtml5} from 'react-icons/di'
import {BiLogoCss3, BiLogoJavascript, BiLogoTailwindCss , BiLogoReact, BiLogoNodejs, BiLogoMongodb, BiLogoPython, BiLogoFirebase} from 'react-icons/bi'
import {TbBrandNextjs} from 'react-icons/tb'
import {GrMysql} from 'react-icons/gr'
import {SiSanity}from 'react-icons/si'

const Experience = () => {
  return (
    <section id="experience">
      <h5>My Skills</h5>
      <h2>my Experience</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <DiHtml5 className='experience__details-icon' />
              <div>
                <h4>HTML</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>

            <article className="experience__details">
              <BiLogoCss3 className='experience__details-icon' />
              <div>
                <h4>CSS</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>

            <article className="experience__details">
              <BiLogoJavascript className='experience__details-icon' />
              <div>
                <h4>JavaScript</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>

            <article className="experience__details">
              <BsBootstrapFill className='experience__details-icon' />
              <div>
                <h4>Bootstrap</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>

            <article className="experience__details">
              <TbBrandNextjs className='experience__details-icon' />
              <div>
                <h4>Next JS</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>

            <article className="experience__details">
              <BiLogoTailwindCss className='experience__details-icon' />
              <div>
                <h4>Tailwind</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>

            <article className="experience__details">
              <BiLogoReact className='experience__details-icon' />
              <div>
                <h4>ReactNative & Daynamic</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
          </div>
        </div>

        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BiLogoNodejs className='experience__details-icon' />
              <div>
                <h4>Node JS</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>

            <article className="experience__details">
              <BiLogoMongodb className='experience__details-icon' />
              <div>
                <h4>MongoDB</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>

            <article className="experience__details">
              <BiLogoFirebase className='experience__details-icon' />
              <div>
                <h4>Firebase</h4>
                <small className="text-light">Basic</small>
              </div>
            </article>

            <article className="experience__details">
              <BsFiletypePhp className='experience__details-icon' />
              <div>
                <h4>PHP</h4>
                <small className="text-light">Basic</small>
              </div>
            </article>

            <article className="experience__details">
              <GrMysql className='experience__details-icon' />
              <div>
                <h4>MySQL</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>

            <article className="experience__details">
              <BiLogoPython className='experience__details-icon' />
              <div>
                <h4>Python</h4>
                <small className="text-light">Basic</small>
              </div>
            </article>

            <article className="experience__details">
              <SiSanity className='experience__details-icon' />
              <div>
                <h4>Sanity</h4>
                <small className="text-light">Basic</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
