import React,{useState} from 'react'
import './nav.css'
import {BiHome} from 'react-icons/bi'
import {BiUser} from 'react-icons/bi'
import {BsBook} from 'react-icons/bs'
import {RiCustomerService2Line} from 'react-icons/ri'
import {BiMessageDetail } from 'react-icons/bi'

const Nav = () => {
  const [activeNav, setActiveNav]=useState('#')
  return (
    <nav>
      <a href="#"  onClick={()=>setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}><BiHome /></a>
      <a href="#about" onClick={()=>setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><BiUser/></a>
      <a href="#experience" onClick={()=>setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}><BsBook /></a>
      <a href="#services" onClick={()=>setActiveNav('#services')} className={activeNav === '#services' ? 'active' : ''} ><RiCustomerService2Line /></a>
      <a href="#contact"onClick={()=>setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''} ><BiMessageDetail /></a>

    </nav>
  )
}

export default Nav