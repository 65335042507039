import React from "react";
import "./about.css";
import ME from "../../assets/emru1.jpeg";
import { FaAward, FaUserSecret } from "react-icons/fa";
import { RiFolderZipFill } from "react-icons/ri";
const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about_icon" />
              <h5>Experience</h5>
              <small>1+ Year Working</small>
            </article>
            <article className="about__card">
              <FaUserSecret className="about_icon" />
              <h5>Client</h5>
              <small>125+ WWB</small>
            </article>
            <article className="about__card">
              <RiFolderZipFill className="about_icon" />
              <h5>Projects</h5>
              <small>10+ completed</small>
            </article>
          </div>
          <p> With a passion for full-stack development, I am a proficient developer capable of designing and developing complex applications with expertise in React, React Dynamic and Node.js.
          </p>
          <a href="#contact" className="btn btn-primary">Contact me</a>
        </div>
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
