import React from 'react'
import './footer.css'
import {BsFacebook, BsInstagram} from 'react-icons/bs'
import {RiTwitterXFill} from 'react-icons/ri'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'></a>
      <ul className="permalinks">
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Exprerience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
      <div className="footer__socials">
        <a href="https://faccebook.com"><BsFacebook /></a>
        <a href="https://twitter.com"><RiTwitterXFill /></a>
        <a href="https:/instagram.com"><BsInstagram /></a>
      </div>
      <div className="footer__copyright">
        <small>&copy; Emru portfolio. All Rights are reserved.</small>
      </div>
    </footer>
  )
}

export default Footer