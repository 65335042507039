import React from "react";
import "./testimonials.css";
import Person1 from "../../assets/person1.jpeg";
import Person2 from "../../assets/person2.jpeg";
import Person3 from "../../assets/person3.jpeg";
import Person4 from "../../assets/person4.jpeg";
import Person5 from "../../assets/person5.jpeg";
// import {Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// import { Scrollbar } from 'swiper/modules';
import { Pagination } from 'swiper/modules';


const data=[
  {
    avator: Person1,
    name: 'Akideret .T',
    review: 'The software he developed are simple for end users, atractive, and platform independence we can see on mobile phones, tablets, and large screens! '
  },
  {
    avator: Person2,
    name: 'Mebrahitu Areya Alene',
    review: 'Well done and i congratulate your effort to design  such a wonder full responsive platform independent web design .I rely appreciate your work and your time .'
  },
  {
    avator: Person3,
    name: 'Hailay Mehari Dr.',
    review: 'He is commited to support and work cooperatively. Not only was my experience personal and friendly, his ability to identify and present in an imaginative and creative way gave me a huge amount of confidence in him. He is reliable, professional and easy to work with. '
  },
  {
    avator: Person4,
    name: 'Tekilegiorgis Tesfay',
    review: 'As a User, it is great for me and easy to perate. also its fully responsive and platform-independent too!'
  },
  {
    avator: Person5,
    name: 'Leul Yitbarek Yihun',
    review: 'I am thinking of having such kind of web-app for my business, it is so attractive and responsive website platform..! '
  }

]

const Testimonials = () => {

  return (
    <section id="testimonials">
      <h5>Review From Clients</h5>
      <h2>Testimonials</h2>
      <Swiper className="container testimonials__container mySwiper"
      //install Swiper modules
      spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      
      >
       {
        data.map(({avator, name, review}, index)=>{
          return(
            <SwiperSlide key={index} className="testimonial">
            <div className="client__avator">
              <img src={avator} />
            </div>
            <h5 className="client__name">{name}</h5>
            <small className="client__review">
              {review}
            </small>
          </SwiperSlide>
          )
        })
       }

      
      </Swiper>
    </section>
  );
};

export default Testimonials;
